import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import { FilterChips, OptionContainer } from './UsersListHeader.styled';
import { ExploreButton, SearchBarContainer, ToolbarContainer } from "@c/users-view/MainContent.styled";
import { connectStore } from "redux-box";
import { module as user } from "store/user";
// import { module as ui } from "store/ui";
import { module as ui } from "@st/ui";
import { module as activity } from "store/activity";
import { MCQCheckBoxContainer, MCQCheckBoxInnerContainer } from "@sc/McqEditor.styled";
import { FilterDropDownMainContainer } from "@sc/custom.styled";
import { NavigationContainer, NavigationHeaderContainer, NavigationToolbar } from "@sc/NavigationBar.styled";
import { module as onboard } from "@st/onboard";
import { module as inviteCode } from "@st/inviteCode";

import Permission from "@s/permission";
import * as XLSX from 'xlsx'


class UsersListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationSelector: "parents",
      searchBarEnable: false,
      displayMenu: false,
      phoneVerified: "",
      userStatus: "",
      gradeSelected: "",
      uploadedFile: "",
      data: [],
      startDate: new Date(),
    };
  }
  ToolbarContainer;
  componentWillReceiveProps(nextProps) {
    let currentPath = window.location.pathname;
    if (nextProps.user) {
      if (currentPath === "/users/children")
        this.normalizeForChild(nextProps.user.filterChildSearch);
      else this.normalizeForParent(nextProps.user.filterParentSearch);
    } else {
      this.setState({
        userStatus: "",
        gradeSelected: "",
        phoneVerified: "",
      });
    }
  }
  normalizeForParent = (filterParam) => {
    this.setState({
      userStatus: filterParam.user__is_active,
      gradeSelected: "",
      phoneVerified: filterParam.user__is_contact_no_verified,
    });
  };

  normalizeForChild = (filterParam) => {
    this.setState({
      userStatus: filterParam.user__is_active,
      phoneVerified: "",
      gradeSelected: filterParam.grade__name__icontains,
    });
  };
  componentDidMount() {
    this.updateNavigationSelector();
    {
      this.props.location&&this.props.location.pathname !== "/users/register" &&
        document.addEventListener("click", this.handleFilterClick);
    }
    document.addEventListener("click", this.handleFilterClick);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleFilterClick);
  }
  handleFilterClick = (evt) => {
    const area = ReactDOM.findDOMNode(this.refs.area);
    // console.log('wooo', area);
    if (!area.contains(evt.target) && this.state.displayMenu) {
      //console.log(area);
      this.setState({
        displayMenu: false,
      });
    }
  };
  enableSearchBar = (actionType) => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true,
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false,
      });
  };

  handleGradeSelected = (val) => {
    this.setState({
      gradeSelected: val,
      displayMenu: false,
    });
    this.props.user.setFilterChildSearch({
      ...this.props.user.filterChildSearch,
      grade__name__icontains: val,
    });
    this.props.user.requestChildren();
  };

  handleNavigationSelector = (currentPath) => {
    this.setState({
      navigationSelector: currentPath,
      displayMenu: false,
    });
  };

  handleExportDataBtn = (el) => {
    let { user } = this.props;
    console.log(user);
    let currentPath = window.location.pathname;
    console.log(currentPath);

    if (currentPath === "/users/children") {
      this.props.user.exportChildData("child");
    } else {
      this.props.user.exportParentData("parent");
    }
  };

  setPhoneVerified = (val) => {
    // console.log("hello");
    this.setState({
      phoneVerified: val,
      displayMenu: false,
    });
    this.props.user.setFilterParentSearch({
      ...this.props.user.filterParentSearch,
      user__is_contact_no_verified: val,
    });
    this.props.user.requestParents();
  };
  setUserStatus = (val) => {
    this.setState({
      userStatus: val,
      displayMenu: false,
    });
    let currentPath = window.location.pathname;

    if (currentPath === "/users/children") {
      this.props.user.setFilterChildSearch({
        ...this.props.user.filterChildSearch,
        user__is_active: val,
      });
      this.props.user.requestChildren();
    } else {
      this.props.user.setFilterParentSearch({
        ...this.props.user.filterParentSearch,
        user__is_active: val,
      });
      this.props.user.requestParents();
    }
  };
  displayMenu = () => {
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  // methods handling search for parents and shild start
  handleChildSearch = (evt) => {
    //console.log(evt.target.value);
    this.props.user.setChildSearch(evt.target.value);
    setTimeout(() => {
      this.props.user.requestChildrenByOffset(0);
    }, 1500);
  };

  handleParentSearch = (evt) => {
    this.props.user.setParentSearch(evt.target.value);
    setTimeout(() => {
      this.props.user.requestParentsByOffset(0);
    }, 1500);
    //this.props.user.requestParents();
  };
  // methods handling search for parents and shild end
  //    cross button methods handling start
  handleClearParentSearch = () => {
    let { user } = this.props;
    user.setParentSearch("");
    user.requestParentsByOffset();
  };

  handleClearChildSearch = () => {
    let { user } = this.props;
    user.setChildSearch("");
    user.requestChildrenByOffset();
  };
  handleExportClick = () => {
    let { user } = this.props;
  };
  //    cross button methods handling start

  // export excel with date parameters methods start
  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;
    this.setState({ startDate, endDate });
  };

  handleChangeStart = (startDate) => this.handleChange({ startDate });

  handleChangeEnd = (endDate) => this.handleChange({ endDate });

  // export excel with date parameters methods end

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.updateNavigationSelector();
    }
  }

  updateNavigationSelector = () => {
    const currentPath = this.props.location && this.props.location.pathname;
    console.log(currentPath,this.props,this.props.location)
    if (
      currentPath === "/users" ||
      currentPath === "/users/parents" ||
      currentPath === "/"
    ) {
      this.setState({
        navigationSelector: "parents",
      });
    } else if (currentPath === "/users/register") {
      this.setState({
        navigationSelector: "register",
      });
    } else {
      this.setState({
        navigationSelector: "children",
      });
    }
  };

  handleDownload = () => {
    let fileName = "sample";
    const data = [
      {
        name: "Rajan",
        contact_no: '7612735672',
        schoolCode: "stzycp",
        state:"Punjab",
        grade: "Grade 3",
        section: "A,B,C",
        is_teacher: "FALSE",
      },
      {
        name: "Rahul",
        contact_no: '7178123455',
        schoolCode: "stzycp",
        state:"Punjab",
        grade: "Grade 2",
        section: "B",
        is_teacher: "TRUE",
      },
      {
        name: "Ram",
        contact_no: '7178123455',
        schoolCode: "stzycp",
        state:"Punjab",
        grade: "Grade 4",
        section: "A",
        is_teacher: "False",
      },
    ];

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary string
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the binary string
    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    // Create a link element
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.xlsx`;

    // Programmatically click the link to trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up and remove the link
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  render() {
    const { open } = this.state;
    let { user } = this.props;
    let currentPath = window.location.pathname;
    const { permission } = this.props.onboard;
    return (
      <NavigationHeaderContainer>
        <NavigationContainer>
          <Link
            to="/users/parents"
            className={
              this.state.navigationSelector === "parents" ? "active" : ""
            }
          >
            <span onClick={() => this.handleNavigationSelector("parents")}>
              Parents
            </span>
          </Link>
          <Link
            to="/users/children"
            className={
              this.state.navigationSelector === "children" ? "active" : ""
            }
          >
            <span onClick={() => this.handleNavigationSelector("children")}>
              Children
            </span>
          </Link>
          <Link
            to="/users/register"
            className={
              this.state.navigationSelector === "register" ? "active" : ""
            }
          >
            <span onClick={() => this.handleNavigationSelector("register")}>
              Register a User
            </span>
          </Link>
        </NavigationContainer>

        <NavigationToolbar>
          {/* export excel new UI start */}
          <div>
            {Permission.checkRole(permission, "superuser") &&
            this.props.location.pathname === "/users/children" ? (
              <ExploreButton
                primary
                disabled={this.props.user.exportData}
                onClick={() => this.props.ui.showModal("EXPORTEXCELMODAL")}
              >
                {" "}
                Export CSV Data
              </ExploreButton>
            ) : null}
          </div>

          {user.filterParentSearch.user__is_active !== "" &&
            (currentPath === "/users" ||
              currentPath === "/users/parents" ||
              currentPath === "/") && (
              <FilterChips onClick={() => this.setUserStatus("")}>
                <img
                  className="image-fluid"
                  src={require("static/img/tagCloseIcon.png")}
                  alt="Edit icon"
                />
                {user.filterParentSearch.user__is_active === "True"
                  ? "Active Users"
                  : "Inactive Users"}
              </FilterChips>
            )}
          {user.filterParentSearch.user__is_contact_no_verified !== "" &&
            (currentPath === "/users" ||
              currentPath === "/users/parents" ||
              currentPath === "/") && (
              <FilterChips onClick={() => this.setPhoneVerified("")}>
                <img
                  className="image-fluid"
                  src={require("static/img/tagCloseIcon.png")}
                  alt="Edit icon"
                />
                Contact No.
                {user.filterParentSearch.user__is_contact_no_verified === "True"
                  ? "Verified"
                  : "Unverified"}
              </FilterChips>
            )}
          {user.filterChildSearch.user__is_active !== "" &&
            currentPath === "/users/children" && (
              <FilterChips onClick={() => this.setUserStatus("")}>
                <img
                  className="image-fluid"
                  src={require("static/img/tagCloseIcon.png")}
                  alt="Edit icon"
                />
                {user.filterChildSearch.user__is_active === "True"
                  ? "Active Users"
                  : "Inactive Users"}
              </FilterChips>
            )}
          {user.filterChildSearch.grade__name__icontains !== "" &&
            currentPath === "/users/children" && (
              <FilterChips onClick={() => this.handleGradeSelected("")}>
                <img
                  className="image-fluid"
                  src={require("static/img/tagCloseIcon.png")}
                  alt="Edit icon"
                />
                {user.filterChildSearch.grade__name__icontains}
              </FilterChips>
            )}

          <SearchBarContainer
            right
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            {currentPath.includes("/users/parents") ||
            currentPath === "/users" ||
            currentPath === "/" ? (
              // <div>
              <input
                type="text"
                placeholder="Search"
                onChange={this.handleParentSearch}
                value={this.props.user.search_parent}
                onFocus={() => this.enableSearchBar("focus")}
                onBlur={() => this.enableSearchBar("blur")}
              ></input>
            ) : (
              //     {/* {this.props.user.search_parent.length > 0 && (
              //         <i
              //             className="fa fa-times cross"
              //             onClick={() => this.handleClearParentSearch()}
              //         />
              //     )} */}

              // {/* </div> */}
              <input
                type="text"
                placeholder="Search"
                onChange={this.handleChildSearch}
                value={this.props.user.search_child}
                onFocus={() => this.enableSearchBar("focus")}
                onBlur={() => this.enableSearchBar("blur")}
              ></input>
            )}
          </SearchBarContainer>
          <ToolbarContainer>
            <div
              style={{ display: "inline-block" }}
              onBlur={this.displayMenu}
              ref="area"
            >
              <img
                src={require("static/img/filter.png")}
                alt="Filter"
                onClick={this.displayMenu}
              />
              {this.state.displayMenu ? (
                <FilterDropDownMainContainer
                  onBlur={this.displayMenu}
                  tabIndex="0"
                >
                  <ul>
                    <li>
                      {" "}
                      Status
                      <ul>
                        <div
                          style={{
                            backgroundColor: "white",
                            marginRight: "2px",
                          }}
                        >
                          <li>
                            <OptionContainer
                              onClick={() => this.setUserStatus("")}
                            >
                              {" "}
                              All{" "}
                              <MCQCheckBoxContainer
                                onClick={() => this.setUserStatus("")}
                              >
                                {this.state.userStatus === "" ? (
                                  <MCQCheckBoxInnerContainer />
                                ) : null}
                              </MCQCheckBoxContainer>
                            </OptionContainer>
                          </li>
                          <li>
                            <OptionContainer
                              onClick={() => this.setUserStatus("True")}
                            >
                              {" "}
                              Enable{" "}
                              <MCQCheckBoxContainer
                                onClick={() => this.setUserStatus("True")}
                              >
                                {this.state.userStatus === "True" ? (
                                  <MCQCheckBoxInnerContainer />
                                ) : null}
                              </MCQCheckBoxContainer>
                            </OptionContainer>
                          </li>
                          <li>
                            <OptionContainer
                              onClick={() => this.setUserStatus("False")}
                            >
                              {" "}
                              Disable
                              <MCQCheckBoxContainer
                                onClick={() => this.setUserStatus("False")}
                              >
                                {this.state.userStatus === "False" ? (
                                  <MCQCheckBoxInnerContainer />
                                ) : null}
                              </MCQCheckBoxContainer>
                            </OptionContainer>
                          </li>
                        </div>
                      </ul>
                    </li>
                    {currentPath === "/users/parents" ||
                    currentPath === "/users" ||
                    currentPath === "/" ? (
                      <li>
                        {" "}
                        Phone Verified
                        <ul>
                          <div
                            style={{
                              backgroundColor: "white",
                              marginRight: "2px",
                            }}
                          >
                            <li>
                              <OptionContainer
                                onClick={() => this.setPhoneVerified("")}
                              >
                                {" "}
                                All{" "}
                                <MCQCheckBoxContainer
                                  onClick={() => this.setPhoneVerified("")}
                                >
                                  {this.state.phoneVerified === "" ? (
                                    <MCQCheckBoxInnerContainer />
                                  ) : null}
                                </MCQCheckBoxContainer>
                              </OptionContainer>
                            </li>
                            <li>
                              <OptionContainer
                                onClick={() => this.setPhoneVerified("True")}
                              >
                                {" "}
                                Yes{" "}
                                <MCQCheckBoxContainer
                                  onClick={() => this.setPhoneVerified("True")}
                                >
                                  {this.state.phoneVerified === "True" ? (
                                    <MCQCheckBoxInnerContainer />
                                  ) : null}
                                </MCQCheckBoxContainer>
                              </OptionContainer>
                            </li>
                            <li>
                              <OptionContainer
                                onClick={() => this.setPhoneVerified("False")}
                              >
                                {" "}
                                No{" "}
                                <MCQCheckBoxContainer
                                  onClick={() => this.setPhoneVerified("False")}
                                >
                                  {this.state.phoneVerified === "False" ? (
                                    <MCQCheckBoxInnerContainer />
                                  ) : null}
                                </MCQCheckBoxContainer>
                              </OptionContainer>
                            </li>
                          </div>
                        </ul>
                      </li>
                    ) : (
                      <li>
                        {" "}
                        Grade
                        {this.props.activity.grades
                          ? this.props.activity.grades && (
                              <ul>
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    marginRight: "2px",
                                    overflowY: "auto",
                                  }}
                                >
                                  <li>
                                    <OptionContainer
                                      onClick={() =>
                                        this.handleGradeSelected("")
                                      }
                                    >
                                      {" "}
                                      All
                                      <MCQCheckBoxContainer
                                        onClick={() =>
                                          this.handleGradeSelected("")
                                        }
                                      >
                                        {this.state.gradeSelected === "" ? (
                                          <MCQCheckBoxInnerContainer />
                                        ) : null}
                                      </MCQCheckBoxContainer>
                                    </OptionContainer>
                                  </li>
                                  {this.props.activity.grades.map((grade) => (
                                    <li key={grade.id}>
                                      <OptionContainer
                                        onClick={() =>
                                          this.handleGradeSelected(grade.name)
                                        }
                                      >
                                        {" "}
                                        {grade.name}
                                        <MCQCheckBoxContainer
                                          onClick={() =>
                                            this.handleGradeSelected(grade.name)
                                          }
                                        >
                                          {this.state.gradeSelected ===
                                          grade.name ? (
                                            <MCQCheckBoxInnerContainer />
                                          ) : null}
                                        </MCQCheckBoxContainer>
                                      </OptionContainer>
                                    </li>
                                  ))}
                                </div>{" "}
                              </ul>
                            )
                          : null}
                      </li>
                    )}
                  </ul>
                </FilterDropDownMainContainer>
              ) : null}
            </div>
            {/* <img src={require("static/img/scroll_right.png")} alt="ScrollRight" /> */}
          </ToolbarContainer>
          {this.props.location &&
            this.props.location.pathname === "/users/register" && (
              <ToolbarContainer
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <ExploreButton
                  primary
                  onClick={() => this.props.ui.showModal("FileUploadModal")}
                >
                  Bulk Registration
                </ExploreButton>
                <ExploreButton primary onClick={() => this.handleDownload()}>
                  Sample Excel File
                </ExploreButton>
              </ToolbarContainer>
            )}
        </NavigationToolbar>
      </NavigationHeaderContainer>
    );
  }
}
// export default UsersListHeader;
export default withRouter(connectStore({
    ui, user, activity,onboard,inviteCode
   })(UsersListHeader))

