import React, { useEffect, useState } from "react";
import { UserInputField } from "../../users-container/user_creater_form/UserCreaterFormStyled";
import Select from "react-select";

import {
    UserListLoadingContainer,
} from "@c/users-view/MainContent.styled";
import { module as user } from "@st/user";
import { module as ui } from "@st/ui";
import { connectStore } from "redux-box";
import { module as onboard } from "@st/onboard";
import { module as activity } from "@st/activity";
import useDebouncing from "../../../../utils/useDebouncing";
import swal from "sweetalert2";
import {
    SaveButton,
} from "components/users-view/parent-profile/ParentProfile.styled";
import './AddChildModal.css'

const AddChildModal = (props) => {

    const [state, setState] = useState({
        userInfo: {
            name: "",
            contact_no: props.user.active_parent ? props.user.active_parent.contact_no : '',
            grade: "",
            schoolCode: "",
            school: "",
            section: "",
        },
    });

    const [allGrades, setAllGrades] = useState([]);
    const [allSections, setAllSections] = useState([]);

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            position: "absolute",
        }),
        control: (provide) => ({
            ...provide,
            width: "95%",
            margin: "0 auto",
            border: "none",
            boxShadow: "none",
        }),
    };

    const debouncedSchoolCode = useDebouncing(state.userInfo.schoolCode, 1000);

    useEffect(() => {
        console.log(props.activity);
        if (props.activity.gradeList.length > 0) {
            setAllGrades(props.activity.gradeList);
        }
    }, [props.activity.gradeList]);

    useEffect(() => {
        if (props.user.sectionsList && props.user.sectionsList.length > 0) {
            setAllSections(props.user.sectionsList);
        }
    }, [props.user.sectionsList]);

    useEffect(() => {
        if (debouncedSchoolCode && debouncedSchoolCode.length == 6) {
            props.user.validateSchool(debouncedSchoolCode);
            setState({
                userInfo: {
                    ...state.userInfo,
                    grade: "",
                    section: "",
                },
            });
        }
    }, [debouncedSchoolCode]);

    useEffect(() => {
        if (props.user.childCreated) {
            props.ui.hideModal();
        }
    }, [props.user.childCreated])

    const handleUserInfo = (type, evt, length) => {
        if (evt.target.value.length <= length) {
            setState({
                userInfo: { ...state.userInfo, [type]: evt.target.value },
            });
        }
    };

    const changeGrade = (selectedGrade) => {
        setState({
            userInfo: { ...state.userInfo, grade: selectedGrade, section: "" },
        });
        setAllSections([]);

        if (props.user.school) {
            let payload = {
                school: props.user.school.id,
                grade: selectedGrade.id,
            };
            props.user.getSections(payload);
        }
    };

    const validate = (name) => {
        let n = name.trim();
        const re = /^[a-zA-Z0-9_ .]*$/;
        if (re.test(n) == true && name.length > 2) return true;
        return false;
    };



    const handleSubmitBtn = async () => {
        const userInfo = state.userInfo;
        const validateName = validate(userInfo.name);
        if (!validateName) {
            swal("Error!", "Please enter a valid name!", "error");
            return;
        }

        if (!userInfo.grade && !userInfo.grade.id) {
            swal("Error!", "Please enter a valid grade!", "error");
            return;
        }

        if (!userInfo.section) {
            swal("Error!", "Please enter a valid section!", "error");
            return;
        }

        const payload = {
            ...state.userInfo,
            grade: [state.userInfo.grade.id],
            section: state.userInfo.section.value,
            school: props.user.school.id,
            add_child: true
        };

        props.user.registerUser(payload);
    };


    return (
        <React.Fragment>
            {
                props.user.creatingChild ?
                    <UserListLoadingContainer>
                        <img
                            alt="loading"
                            width="40"
                            height="40"
                            src={require("static/img/Theme-loader.gif")}
                        />
                    </UserListLoadingContainer>
                    :
                    <div className="add_child_modal_container">
                        <UserInputField
                            type="text"
                            placeholder="Name of the User"
                            border={"2px solid #004ea3"}
                            radius={"10px"}
                            fontSize={"14px"}
                            margin={"3px"}
                            value={state.userInfo.name}
                            onChange={(evt) => handleUserInfo("name", evt, 100)}
                        />
                        <UserInputField
                            type="text"
                            // disabled={debouncedSchoolCode.length === 6}
                            placeholder="Enter School Code"
                            border={"2px solid #004ea3"}
                            radius={"10px"}
                            fontSize={"14px"}
                            margin={"3px"}
                            value={state.userInfo.schoolCode}
                            onChange={(evt) => handleUserInfo("schoolCode", evt, 6)}
                        />
                        {props.user.school && (
                            <UserInputField
                                type="text"
                                disabled={true}
                                border={"2px solid #004ea3"}
                                radius={"10px"}
                                fontSize={"14px"}
                                margin={"3px"}
                                value={props.user.school && props.user.school.name}
                            />
                        )}
                        <Select
                            className="dropdown-container"
                            value={state.userInfo.grade}
                            onChange={changeGrade}
                            options={allGrades && allGrades.length >= 1 && allGrades}
                            placeholder="Select Grade"
                            // styles={{ border: "2px solid #004ea3" }}
                            styles={customStyles}
                            menuPlacement="auto" // auto, top, or bottom
                            menuPosition="fixed"
                        // menuIsOpen={allGrades && allGrades.length >= 1 && true}
                        />

                        <Select
                            className="dropdown-container"
                            value={state.userInfo.section}
                            onChange={(selectedSection) =>
                                setState({
                                    userInfo: { ...state.userInfo, section: selectedSection },
                                })
                            }
                            options={allSections && allSections}
                            placeholder="Select Section"
                            styles={customStyles}
                            menuPlacement="auto" // auto, top, or bottom
                            menuPosition="fixed"
                            isMulti={
                                state.userInfo.is_teacher && state.userInfo.is_teacher.value
                            }
                        />

                        <SaveButton primary onClick={handleSubmitBtn}>
                            Add Child
                        </SaveButton>
                    </div>
            }
        </React.Fragment>
    )
}

export default connectStore({
    user,
    onboard,
    activity,
    ui
})(AddChildModal)